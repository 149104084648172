import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Scrollama, Step } from "react-scrollama";
import Layout from "../../components/layout";
import Header from "../../components/header";
import ErrorBoundary from "../../components/common/errorBoundary";
import ConsultCard from "../../components/care/ConsultCard";
import CoachingIntro from "../../components/care/coachingIntro";
import CareIntroV2 from "../../components/care/careIntroV2";
import HowItWorks from "../../components/care/howItWorks";
import AdditionalCareOptions from "../../components/care/additionalCareOptions";
import TrialStats from "../../components/care/trialStats";
import AskEvvy from "../../components/care/askEvvy";
import { CareComparison } from "../../components/care/careComparison";
import { CareReview } from "../../components/care/careReview";
import { SampleTreatmentEntry } from "../../components/care/sampleTreatmentEntry";
import CareFAQs from "../../components/care/careFAQs";
import GetCareStickyFooter from "../../components/care/getCareStickyFooter";
import ReferralNote from "../../components/care/referralNote";
import LoadingSpinner from "../../components/common/loadingSpinner";
import PricingTemplate from "../../components/plan_unfurled/modalTemplates/pricing";
import ReferredTemplate from "../../components/care/modalTemplates/referred";
import Modal from "../../components/common/modal";
import { testsService } from "../../services/tests";

import {
  sendCareViewedPage,
  eventNames,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { modalTypes as MTYPES } from "../plan_unfurled/constants";
import { getIsMobile } from "../../utils/general";
import { CareValueProps } from "../../components/care/careValueProps";
import ShopTreatmentsOnramp from "../../components/care/shopTreatmentsOnramp";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { BaseAnalyticsArgsContext } from "../../contexts/baseAnalyticsArgsContext";
import {
  getButtonCTATextForConsultation,
  handleCareIntroCTAClick,
} from "../../components/care/utils";
import { useProblemsSummary } from "../../hooks/care/useProblemsSummary";
import { useExperiments } from "../../hooks/useExperiments";
import { cn } from "../../utils/cn";

/* Modal Handler
  Modal wrapper renders child template within it
  Switch statement handles which modal to render and passes data along to template
 */
const renderModal = ({ type, data }, closeModal) => {
  switch (type) {
    case MTYPES.PRICING: {
      return <PricingTemplate handleClose={closeModal} data={data} />;
    }
    case "referral": {
      return <ReferredTemplate data={data} handleClose={closeModal} />;
    }

    default: {
      throw new Error("Invalid modal type");
    }
  }
};

// Shows user's care landing page. If no consults, lets them opt-in. If existing, redirect them.
const CareLandingV2 = ({ completedConsults }) => {
  // state management
  const [test, setTest] = useState(null);
  const [consult, setConsult] = useState(null);
  const [loading, setLoading] = useState(true); // loading state for fetching test + consult
  const [error, setError] = useState(null); // error state for fetching test + consult
  const [modalData, setModalData] = useState(null); // modal data for modal wrapper
  const PRE_REVIEW_STATUSES = ["IN", "OR", "ST"]; // consult statuses that are "pre-review"
  const [selectedPathwaySlug, setSelectedPathwaySlug] = useState("");
  const hasPastTreatments =
    consult?.status === "CP"
      ? completedConsults?.length > 1
      : completedConsults?.length > 0;
  const hasPastConsultMessages = completedConsults.some(
    (c) => c?.consultmessagethread
  );
  const experiments = useExperiments();

  // route params
  const navigate = useNavigate();

  // context
  const currentUser = useLoggedInUser();

  // problems summary infection state
  const { testSummary } = useProblemsSummary(test?.hash);

  // scroll state
  const [currentStep, setCurrentStep] = useState(0);

  const isMobile = getIsMobile();
  // const for analytics
  const CARE_PAGE_VERSION = "v2";
  // scroll cutoff
  const SCROLL_CUTOFF = isMobile ? 0.85 : 0.8;

  const isFirstTimeVaginitisCareUser =
    !currentUser.care.vaginitis.hasCompletedConsult;

  const ctaText = getButtonCTATextForConsultation(
    consult,
    isFirstTimeVaginitisCareUser
  );

  const paidForALaCare =
    consult?.purchase_type === "a-la-care" && consult?.consult_paid;

  const eligibleForAnyCare =
    test?.eligible_for_care || test?.eligible_for_a_la_care;

  /* Effects */
  useEffect(() => {
    fetchLatestReadyTest();
    window.scrollTo(0, 0); // start from top!
  }, []);

  useEffect(() => {
    if (test) {
      const eligibleTreatmentPathways =
        consult?.consult_paid && consult?.treatment_pathway
          ? [consult.treatment_pathway]
          : test.eligible_treatment_pathways;
      if (eligibleTreatmentPathways.length > 0) {
        setSelectedPathwaySlug(eligibleTreatmentPathways[0]?.slug);
      }
    }
  }, [test]);

  // analytics
  useEffect(() => {
    if (test && testSummary) {
      const recordViewedPage = (latestTest, testSummary) => {
        sendCareViewedPage(
          getCarePageAnalyticsEventArgs(latestTest, testSummary)
        );
      };
      recordViewedPage(test, testSummary);
    }
  }, [test, testSummary]);

  /* Actions */
  // fetch user's latest ready test
  const fetchLatestReadyTest = () => {
    setLoading(true);
    testsService.fetchLatestReadyTest(
      (response) => {
        setTest(response.data);
        if (response.data.latest_vaginitis_consult) {
          setConsult(response.data.latest_vaginitis_consult);
        }
        setLoading(false);
      },
      (error, response) => {
        setError(response);
      }
    );
  };

  // scroll actions
  const onStepEnter = ({ data }) => {
    setCurrentStep(data);
  };

  const onStepExit = ({ data }) => {
    if (data === 1) {
      setCurrentStep(0);
    }
  };

  const onStepProgress = ({ data, progress }) => {
    if (data >= 1 && progress >= SCROLL_CUTOFF) {
      setCurrentStep(0);
    } else if (data >= 1 && progress < SCROLL_CUTOFF) {
      setCurrentStep(data);
    }
  };

  // utils
  const getCarePageStatus = (latestTest) => {
    if (
      !eligibleForAnyCare ||
      !latestTest?.latest_vaginitis_consult ||
      PRE_REVIEW_STATUSES.includes(latestTest?.latest_vaginitis_consult?.status)
    )
      return "Start";
    else if (latestTest?.latest_vaginitis_consult?.status === "CP")
      return "Ready";
    else return "In Review";
  };

  const getBaseAnalyticsEventArgs = () => {
    return {
      consultId: consult?.uid,
      testHash: test?.hash,
      testOrder: test?.test_order,
      careEligible: test?.eligible_for_care,
      carePrice: test?.eligible_for_care
        ? test?.care_pricing?.discounted_total
        : null,
      consultStatus: consult?.get_status_display,
      carePageStatus: getCarePageStatus(test),
      pageVersion: CARE_PAGE_VERSION,
    };
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  const getCarePageAnalyticsEventArgs = (latestTest, testSummary) => {
    const carePageStatus = getCarePageStatus(latestTest);
    return {
      consultId: latestTest?.latest_vaginitis_consult?.uid,
      testHash: latestTest?.hash,
      careEligible: latestTest?.eligible_for_care,
      eligibleTreatmentPathways:
        latestTest?.eligible_treatment_pathways &&
        latestTest?.eligible_treatment_pathways.length > 0
          ? latestTest?.eligible_treatment_pathways.map(
              (pathway) => pathway.slug
            )
          : [],
      carePrice: latestTest?.eligible_for_care
        ? latestTest?.care_pricing?.discounted_total
        : null,
      consultStatus: latestTest?.latest_vaginitis_consult?.get_status_display,
      carePageStatus,
      pageVersion: CARE_PAGE_VERSION,
      microbiomeState: testSummary?.problems_summary.microbiome_state,
      conditions: testSummary?.problems_summary.conditions,
      symptoms: testSummary?.problems_summary.symptoms,
      aLaCareEligible: latestTest?.eligible_for_a_la_care,
    };
  };

  const carePageAnalyticsEventArgs = useMemo(() => {
    return getCarePageAnalyticsEventArgs(test, testSummary);
  }, [test, testSummary, getCarePageAnalyticsEventArgs]);

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <BaseAnalyticsArgsContext.Provider
      value={{ baseAnalyticsArgs: getBaseAnalyticsEventArgs() }}
    >
      <Layout title="Care" noHeader full>
        {/* modal */}
        {modalData !== null && (
          <ErrorBoundary>
            {/* parent modal wrapper  (handles modal functionality) */}
            <Modal
              closeModal={() => handleCloseModal()}
              widthClass={
                modalData.type === MTYPES.PRICING
                  ? "max-w-lg"
                  : "w-plan-column-sm"
              }
              hasFocusItem
            >
              {/* specific template for modal */}
              {renderModal(modalData, () => handleCloseModal())}
            </Modal>
          </ErrorBoundary>
        )}
        {/* main page layout */}
        <main role="main" className="min-w-full">
          {/* loading state */}
          {loading || !test ? (
            <ErrorBoundary>
              {/* full height gradient */}
              <section
                className={`flex flex-col min-h-screen bg-cover bg-no-repeat`}
              >
                <div className="grow-0 w-full">
                  <Header />
                </div>
                <LoadingSpinner />
              </section>
            </ErrorBoundary>
          ) : (
            <ErrorBoundary>
              {/* full height gradient */}
              <section
                className={`flex flex-col ${
                  !isMobile &&
                  ((test?.eligible_for_care && !consult) ||
                    PRE_REVIEW_STATUSES.includes(consult?.status))
                    ? ""
                    : ""
                } bg-cover bg-no-repeat ${isMobile ? "pb-5" : ""}`}
                style={{
                  backgroundImage: isMobile
                    ? "url('/images/graphics/blue-green-gradient-mobile.png')"
                    : "url('/images/graphics/blue-green-gradient.png')",
                }}
              >
                <div className="grow-0 w-full">
                  <Header
                    noBottomPadding={true}
                    noRoundedBottom={!consult?.consult_paid}
                  />
                </div>

                {/* if consult was referred out by clinician, show referral note */}
                {consult && consult.status === "RF" && (
                  <ReferralNote
                    consult={consult}
                    openModal={(d) => setModalData(d)}
                  />
                )}

                {eligibleForAnyCare && consult && consult.consult_paid ? (
                  <ConsultCard
                    consult={consult}
                    test={test}
                    analyticsEventArgs={getBaseAnalyticsEventArgs()}
                    completedConsults={completedConsults}
                  />
                ) : test && !loading && test?.eligible_for_care ? (
                  <CareIntroV2
                    consult={consult}
                    test={test}
                    selectedPathwaySlug={selectedPathwaySlug}
                    setSelectedPathwaySlug={setSelectedPathwaySlug}
                    openPriceModal={(price) =>
                      setModalData({
                        type: MTYPES.PRICING,
                        data: {
                          telehealth_fee: price
                            ? price.telehealthFee
                            : test.care_pricing.telehealth_fee,
                          prescription_services: price
                            ? price.prescriptionServices
                            : test.care_pricing.prescription_services,
                        },
                      })
                    }
                    analyticsEventArgs={getBaseAnalyticsEventArgs()}
                    completedConsults={completedConsults}
                  />
                ) : test && !loading && !test?.eligible_for_care ? (
                  <CoachingIntro
                    consult={consult}
                    test={test}
                    currentUser={currentUser}
                    openPriceModal={() =>
                      setModalData({
                        type: MTYPES.PRICING,
                        data: test?.care_pricing,
                      })
                    }
                    analyticsEventArgs={getBaseAnalyticsEventArgs()}
                    completedConsults={completedConsults}
                  />
                ) : (
                  ""
                )}
                {test?.eligible_for_care &&
                  test?.eligible_for_a_la_care &&
                  !consult?.consult_paid &&
                  experiments.prominentOnramp && (
                    <ErrorBoundary>
                      <ShopTreatmentsOnramp
                        eligibleTreatmentPathways={
                          test?.eligible_treatment_pathways
                        }
                        analyticsEventArgs={carePageAnalyticsEventArgs}
                        selectedPathwaySlug={selectedPathwaySlug}
                      />
                    </ErrorBoundary>
                  )}
                {/* "View past treatments" and "View past messages" action is on landing here,
                just not for ineligible care users where the action shows up under the "book coaching" card */}
                {eligibleForAnyCare &&
                (hasPastTreatments || hasPastConsultMessages) ? (
                  <div
                    className={cn("mb-4 sm:mb-8 text-center", {
                      "mt-8": eligibleForAnyCare,
                    })}
                  >
                    {hasPastTreatments && (
                      <Link
                        to="/care/past-treatments/"
                        className="linkedText underline caption medium border-b border-evvy-black cursor-pointer w-max p-pd"
                        onClick={analyticsClickHandler({
                          eventName:
                            eventNames.CARE_CLICKED_VIEW_PAST_CONSULTATIONS,
                          eventArgs: getBaseAnalyticsEventArgs(),
                        })}
                      >
                        View past treatment programs
                      </Link>
                    )}

                    {hasPastTreatments && hasPastConsultMessages && (
                      <span className="inline-block caption px-2">|</span>
                    )}

                    {hasPastConsultMessages && (
                      <Link
                        to="/care/consults/messages/"
                        className="linkedText underline caption medium border-b border-evvy-black cursor-pointer w-max p-pd"
                        onClick={analyticsClickHandler({
                          eventName: eventNames.CARE_CLICKED_VIEW_PAST_MESSAGES,
                          eventArgs: getBaseAnalyticsEventArgs(),
                        })}
                      >
                        View past messages
                      </Link>
                    )}
                  </div>
                ) : eligibleForAnyCare ? (
                  <div className="mb-8" />
                ) : (
                  ""
                )}
              </section>
            </ErrorBoundary>
          )}

          <Scrollama
            onStepEnter={onStepEnter}
            onStepExit={onStepExit}
            onStepProgress={onStepProgress}
            offset={0.1}
          >
            <Step data={1} key="1">
              <div>
                {test && eligibleForAnyCare && (
                  <ErrorBoundary>
                    <section className="bg-evvy-white px-7 pt-12 pb-16 sm:p-24">
                      <HowItWorks careType={"v0_bundle"} />
                    </section>
                  </ErrorBoundary>
                )}
                {test?.eligible_for_care &&
                  test?.eligible_for_a_la_care &&
                  !consult?.consult_paid &&
                  !experiments.prominentOnramp && (
                    <ErrorBoundary>
                      <ShopTreatmentsOnramp
                        eligibleTreatmentPathways={
                          test?.eligible_treatment_pathways
                        }
                        analyticsEventArgs={carePageAnalyticsEventArgs}
                        selectedPathwaySlug={selectedPathwaySlug}
                      />
                    </ErrorBoundary>
                  )}
                {test && test.eligible_for_care && (
                  <ErrorBoundary>
                    <CareValueProps
                      consult={consult}
                      test={test}
                      selectedPathwaySlug={selectedPathwaySlug}
                    />
                  </ErrorBoundary>
                )}
                {test && test.eligible_for_care && (
                  <ErrorBoundary>
                    <section className="bg-evvy-cream">
                      <CareComparison />
                    </section>
                  </ErrorBoundary>
                )}
                {test && test.eligible_for_care && (
                  <ErrorBoundary>
                    <section className="bg-evvy-white">
                      <CareReview />
                    </section>
                  </ErrorBoundary>
                )}
                {test && test.eligible_for_care && (
                  <ErrorBoundary>
                    <section className="bg-evvy-cream">
                      <TrialStats
                        analyticsEventArgs={getBaseAnalyticsEventArgs()}
                      />
                    </section>
                  </ErrorBoundary>
                )}
                {test && !eligibleForAnyCare && !paidForALaCare && (
                  <ErrorBoundary>
                    <section className="bg-evvy-cream px-4 pt-12 pb-16 sm:p-24">
                      <AdditionalCareOptions
                        consult={consult}
                        analyticsEventArgs={getBaseAnalyticsEventArgs()}
                      />
                    </section>
                  </ErrorBoundary>
                )}
                {!paidForALaCare && (
                  <ErrorBoundary>
                    <section className="bg-evvy-cream">
                      <CareFAQs
                        consult={consult}
                        aLaCareEligible={test?.eligible_for_a_la_care}
                        v0BundleEligible={test?.eligible_for_care}
                        analyticsEventArgs={getBaseAnalyticsEventArgs()}
                        faqsPage={"care"}
                      />
                    </section>
                  </ErrorBoundary>
                )}
                {test &&
                  test.eligible_for_care &&
                  !isFirstTimeVaginitisCareUser &&
                  !paidForALaCare && (
                    <ErrorBoundary>
                      <section className="bg-evvy-dark-cream px-4 py-10 sm:py-16">
                        <SampleTreatmentEntry />
                      </section>
                    </ErrorBoundary>
                  )}
                {test && eligibleForAnyCare && (
                  <ErrorBoundary>
                    <section className="bg-evvy-cream px-4 pt-12 pb-16 sm:p-24">
                      <AdditionalCareOptions
                        consult={consult}
                        analyticsEventArgs={getBaseAnalyticsEventArgs()}
                      />
                    </section>
                  </ErrorBoundary>
                )}
                {test && !eligibleForAnyCare && (
                  <ErrorBoundary>
                    <section className="bg-evvy-cream px-4 pt-12 pb-16 sm:p-24">
                      <AskEvvy
                        analyticsEventArgs={getBaseAnalyticsEventArgs()}
                      />
                    </section>
                  </ErrorBoundary>
                )}
              </div>
            </Step>
          </Scrollama>
          {test &&
            test.eligible_for_care &&
            !consult?.consult_paid &&
            currentStep === 1 && (
              <GetCareStickyFooter
                ctaText={ctaText}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CARE_CLICKED_GET_CARE_FOOTER,
                  eventArgs: getBaseAnalyticsEventArgs(),
                  clickHandler: () => {
                    handleCareIntroCTAClick(
                      test,
                      consult,
                      false,
                      navigate,
                      ctaText,
                      "care-footer",
                      selectedPathwaySlug
                    );
                  },
                })}
              />
            )}
        </main>
      </Layout>
    </BaseAnalyticsArgsContext.Provider>
  );
};

export default CareLandingV2;
