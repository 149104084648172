import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Markdown from "markdown-to-jsx";
import Tippy from "@tippyjs/react";

import { ReactComponent as Union } from "../care/icons/union.svg";
import { ReactComponent as Info } from "../plan_unfurled/icons/info.svg";
import BlueRectangularButton from "../common/blueRectangularButton";
import { TreatmentPathwayCards } from "./treatmentOptions/treatmentPathwayCards";

import {
  eventNames,
  sendClickedThroughAntibioticsDisclaimer,
  sendExpandWhatYouGetWithEvvy,
  sendRemoveAntibioticsClicked,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";

import { Bullets } from "../common/bullets";
import { Consult } from "../../types/care";
import { Test } from "../../types/test";
import {
  careLandingCopy,
  CARE_CARD_SUBHEAD,
  CARE_SHIPPING_HSA_COPY,
} from "./constants";
import { CollapsibleDropdown } from "../common/collapsibleDropdown";
import {
  getButtonCTATextForConsultation,
  handleCareIntroCTAClick,
} from "./utils";
import Modal from "../common/modal";
import { WhiteRectangularButton } from "./consultIntake";
import { CARE_CARD_LEARN_MORE } from "../plan_unfurled/constants";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { REMOVE_ANTIBIOTICS_DISCOUNT } from "./constants";
import { cn } from "../../utils/cn";
import { useExperiments } from "../../hooks/useExperiments";

const PRICE_RANGE_DISCLAIMER = "*Actual price dependent on your program";

export const CareIntroDefault = ({
  consult,
  completedConsults,
  test,
  openPriceModal,
  analyticsEventArgs,
  location,
  showCollapsibleDropdown,
  selectedPathwaySlug,
  setSelectedPathwaySlug,
}: {
  consult: Consult;
  completedConsults: Consult[];
  test: Test;
  openPriceModal: (price: {
    prescriptionServices: number;
    telehealthFee: number;
  }) => void;
  analyticsEventArgs: any;
  location: string;
  showCollapsibleDropdown?: boolean;
  selectedPathwaySlug: string;
  setSelectedPathwaySlug: (pathway: string) => void;
}) => {
  const disclaimerModalSeen =
    localStorage.getItem("removeAntibioticsDisclaimerSeen") === "true";
  const [removeAntibiotics, setRemoveAntibiotics] = useState<boolean>(false);
  const [disclaimerModalOpen, setDisclaimerModalOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const currentUser = useLoggedInUser();
  const firstTimeVaginitisCare =
    !currentUser.care.vaginitis.hasCompletedConsult;
  const experiments = useExperiments();

  // if they've already paid, then show their selected treatment pathway as their only eligible treatment pathway
  const eligibleTreatmentPathways = useMemo(() => {
    return consult?.consult_paid && consult?.treatment_pathway
      ? [consult.treatment_pathway]
      : test.eligible_treatment_pathways;
  }, [consult, test]);

  const currentPrice = removeAntibiotics
    ? {
        discountedTotal:
          eligibleTreatmentPathways[0]?.pricing_info.discounted_total -
          REMOVE_ANTIBIOTICS_DISCOUNT,
        fullTotal:
          eligibleTreatmentPathways[0]?.pricing_info.full_total -
          REMOVE_ANTIBIOTICS_DISCOUNT,
      }
    : {
        discountedTotal:
          eligibleTreatmentPathways[0]?.pricing_info.discounted_total,
        fullTotal: eligibleTreatmentPathways[0]?.pricing_info.full_total,
      };

  const hasPastTreatments =
    consult?.status === "CP"
      ? completedConsults?.length > 1
      : completedConsults?.length > 0;
  const hasPastConsultMessages = completedConsults.some(
    (c) => c?.consultmessagethread
  );

  const ctaCopy = getButtonCTATextForConsultation(
    consult,
    firstTimeVaginitisCare
  );

  const showMultipleTreatmentPathways = eligibleTreatmentPathways.length > 1;
  const hasMaintenancePathway = eligibleTreatmentPathways.some(
    (pathway) => pathway.type === "maintenance"
  );

  // Someone can be eligible for both a maintenance and a regular treatment plan, so in that case hasMaintenancePathway will be true.
  // But someone can NOT be eligible for both a maintenance and a probiotics only plan, so if hasProbitiocsOnly is true,
  // then hasMaintenancePathway will be false and vice versa.
  const hasProbioticsOnly = eligibleTreatmentPathways.some(
    (pathway) => pathway.slug === "probiotics-only"
  );
  const copy = hasMaintenancePathway
    ? careLandingCopy.maintenance
    : hasProbioticsOnly
    ? careLandingCopy.probioticsOnly
    : careLandingCopy.removeAntibiotics;
  const bullets = copy.bullets.map((bullet) => {
    return (
      <div className="flex flex-col">
        {bullet.text}
        {bullet.subtext && (
          <div className="text-sm mt-2 b3 opacity-75">
            <Markdown>{bullet.subtext}</Markdown>
          </div>
        )}
      </div>
    );
  });

  const careCardSubhead = showMultipleTreatmentPathways
    ? CARE_CARD_SUBHEAD.default
    : hasMaintenancePathway
    ? CARE_CARD_SUBHEAD.maintenance
    : hasProbioticsOnly
    ? CARE_CARD_SUBHEAD.probioticsOnly
    : CARE_CARD_SUBHEAD.default;

  const treatmentPathwayTitle = useMemo(() => {
    if (eligibleTreatmentPathways[0]?.type === "maintenance") {
      return "Evvy Maintenance Program";
    }
    if (eligibleTreatmentPathways[0]?.slug === "probiotics-only") {
      return "Evvy Probiotics Program";
    }
    return "Evvy Complete Treatment Program";
  }, [eligibleTreatmentPathways]);

  const careDisclaimerCopy = `Your specific ${eligibleTreatmentPathways[0].type} program is determined by a provider.`;

  return (
    <>
      {disclaimerModalOpen && (
        <RemoveAntibioticsDisclaimer
          location={location}
          testHash={test.hash}
          closeModal={() => setDisclaimerModalOpen(false)}
          onDisclaimerAccepted={() => {
            sendRemoveAntibioticsClicked({
              removeAntibiotics: true,
              testHash: test.hash,
              location: location,
            });
            setRemoveAntibiotics(true);
            setDisclaimerModalOpen(false);
          }}
        />
      )}
      <div className="flex flex-col px-2">
        <h2 className="hidden md:block md:mx-auto text-center mt-4">
          {careCardSubhead}
        </h2>
        <h3 className="block md:hidden text-center mt-4">{careCardSubhead}</h3>
        <div
          className={`mb-4 text-center px-6 font-medium leading-[24px] text-[14px] md:text-[18px]`}
        >
          {copy.descriptionOne}
        </div>

        <div className="flex flex-col rounded-xl overflow-hidden md:grid md:grid-cols-2 md:mx-8 md:mt-8">
          <div className="md:order-2 relative bg-evvy-dark-cream">
            <img
              src="/images/graphics/care-packaging-symptom-relief-square.png"
              loading="lazy"
              alt="Care Packaging"
              className="object-cover"
            />
            <img
              src="/images/graphics/symptom-relief-sticker.png"
              loading="lazy"
              alt="Care Packaging"
              className="absolute top-0 right-0 w-1/4 mt-4 mr-4 md:w-1/5"
            />
            <div className="flex bg-transparent p-4 space-x-2 justify-center absolute bottom-0 left-0 right-0">
              <Union className="w-fit" />
              <div className="uppercase font-semibold tracking-wider text-sm text-center my-auto">
                Developed by leading OB-GYNs
              </div>
            </div>
          </div>
          <div
            className={cn("h-full flex flex-col justify-center pt-8", {
              "bg-white pb-4": !showMultipleTreatmentPathways,
            })}
          >
            {showMultipleTreatmentPathways ? (
              <div className="px-0 md:px-8 pt-4 md:pt-0 pb-4">
                <TreatmentPathwayCards
                  treatmentPathways={eligibleTreatmentPathways}
                  selectedPathwaySlug={selectedPathwaySlug}
                  setSelectedPathwaySlug={setSelectedPathwaySlug}
                  analyticsArgs={{ testHash: test.hash, location: "care" }}
                  previousTreatmentPathway={
                    test.previous_test_care.previous_treatment_pathway
                  }
                />
              </div>
            ) : (
              <div className="px-4 md:px-8 flex flex-col">
                <div className="text-xl leading-snug sm:text-2xl font-medium mb-2 sm:mb-1">
                  {treatmentPathwayTitle}
                </div>
                <div className="text-lg md:text-xl semibold md:text-left text-dv-pond">{`$${currentPrice.discountedTotal}`}</div>

                {showCollapsibleDropdown && (
                  <CollapsibleDropdown
                    headerText="What's included"
                    className="md:hidden mt-6 mb-0"
                    handleOpen={(open) => {
                      if (open) {
                        sendExpandWhatYouGetWithEvvy({
                          location: location,
                          testHash: test.hash,
                          carePrice: currentPrice.discountedTotal,
                        });
                      }
                    }}
                  >
                    <Bullets bullets={bullets} />
                  </CollapsibleDropdown>
                )}
                <Bullets
                  bullets={bullets}
                  className={`mt-4 md:mt-6 mb-0 md:flex md:flex-col grow ${
                    showCollapsibleDropdown && "hidden"
                  }`}
                />
              </div>
            )}

            {!showMultipleTreatmentPathways && (
              <hr className="md:mt-8 md:mb-8" />
            )}
            <div className="flex flex-col px-4 md:px-8 mt-2">
              <BlueRectangularButton
                text={ctaCopy}
                onClick={() => {
                  handleCareIntroCTAClick(
                    test,
                    consult,
                    removeAntibiotics,
                    navigate,
                    ctaCopy,
                    location,
                    selectedPathwaySlug
                  );
                }}
                fullWidth
              />
              <div className="b2 mt-4 md:mt-5 md:mb-0 text-center caption">
                {careDisclaimerCopy} <br className="hidden sm:block" />
                {CARE_SHIPPING_HSA_COPY}{" "}
                {location === "plan" && (
                  <Link
                    to="/care/"
                    className="inline border-b border-evvy-black cursor-pointer"
                    onClick={analyticsClickHandler({
                      eventName: eventNames.PLAN_CLICKED_LEARN_MORE_CARE,
                      eventArgs: {
                        ...analyticsEventArgs,
                        location: "plan-care-card",
                        ctaText: ctaCopy,
                      },
                    })}
                  >
                    {CARE_CARD_LEARN_MORE}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AntibioticsTooltip = ({ className }: { className?: string }) => {
  return (
    <Tippy
      arrow
      render={() => (
        <div
          id="tooltip"
          role="tooltip"
          className="flex bg-evvy-pine p-3 rounded-md max-w-sm"
          tabIndex={-1}
        >
          <div className="text-evvy-white">
            Providers may prescribe antibiotics or anti-fungals as part of your
            treatment program at their discretion.{" "}
            <b>
              If you prefer to receive a probiotics / supplements only treatment
              plan
            </b>{" "}
            from Evvy, let your provider know know by checking this box.
          </div>
        </div>
      )}
      placement="bottom"
      animation={false}
    >
      <Info
        stroke="currentColor"
        fill="currentColor"
        className={`ml-1.5 w-4 h-4 text-evvy-black-dull/75 ${className}`}
      />
    </Tippy>
  );
};

const ANTIBIOTICS_DISCLAIMER_COPY =
  "Providers often prescribe antibiotics or anti-fungals to reduce the disruptive microbes present. Please confirm that you'd like your provider to exclude all antibiotics and anti-fungals from your treatment program.";

export const RemoveAntibioticsDisclaimer = ({
  closeModal,
  onDisclaimerAccepted,
  location,
  testHash,
}: {
  closeModal: () => void;
  onDisclaimerAccepted: () => void;
  location: string;
  testHash: string;
}) => {
  return (
    <Modal closeModal={closeModal} widthClass="md:max-w-xl">
      <div className="px-4 py-8">
        <h5 className="text-center text-[24px] mb-4">Are you sure?</h5>
        <div className="text-center mb-8 font-medium leading-[24px] text-[16px]">
          {ANTIBIOTICS_DISCLAIMER_COPY}
        </div>
        <div className="flex flex-col gap-3 md:flex-row">
          <WhiteRectangularButton
            text={"Keep Antimicrobials"}
            onClick={() => {
              sendClickedThroughAntibioticsDisclaimer({
                location,
                testHash: testHash,
                ctaText: "Keep Antimicrobials",
              });
              closeModal();
            }}
            hasMargin={false}
            fullWidth
          />
          <BlueRectangularButton
            text={"Yes, Remove"}
            onClick={() => {
              sendClickedThroughAntibioticsDisclaimer({
                location,
                testHash: testHash,
                ctaText: "Yes, Remove",
              });
              onDisclaimerAccepted();
            }}
            fullWidth
          />
        </div>
      </div>
    </Modal>
  );
};
